<template>
    <v-container>
        <v-col>
            <v-row align="center">
                <v-col class="shrink pr-0">
                    <h1 class="headline" style="white-space: nowrap">Editar zona</h1>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <v-form>
                        <v-text-field
                            label="Nombre"
                            v-model="data.name"
                        ></v-text-field>

                        <product-select
                            v-model="data.shippingCostProduct"
                            :products="products"
                        />

                        <v-text-field
                            label="Tiempo de espera"
                            color="primary"
                            v-model="data.waitingTime"
                        ></v-text-field>
                    </v-form>
                </v-col>
            </v-row>

            <v-spacer></v-spacer>

            <v-row justify="end">
                <v-btn color="primary"
                       @click="onSaveSelected"
                       :disabled="saving"
                       :loading="saving"
                >
                    Guardar
                </v-btn>
            </v-row>
        </v-col>
    </v-container>
</template>

<script>
    import ProductSelect from '@/components/Product/ProductSelect';

    export default {
        components: {
            ProductSelect
        },

        props: {
            deliveryZone: Object
        },

        data() {
            return {
                saving: false,

                data: {
                    shippingCostProduct: null,
                    name: null,
                    waitingTime: null
                }
            };
        },

        computed: {
            products() {
                return this.$store.getters['products/getAll']();
            },

            isLoading() {
                return this.$store.getters['products/isLoading']();
            }
        },

        mounted() {
            this.$store.dispatch('products/fetchAll');

            this.data.shippingCostProduct = this.deliveryZone.shippingCostProduct;
            this.data.name = this.deliveryZone.name;
            this.data.waitingTime = this.deliveryZone.waitingTime;
        },

        methods: {
            onSaveSelected() {
                this.saving = true;

                let data = { ...this.data };
                data.shippingCostProduct = this.data.shippingCostProduct.id;

                this.$store.dispatch('deliveryZones/patch', { id: this.deliveryZone.id, data: data }).then(() => {
                    this.$emit('saved');
                }).finally(() => {
                    this.saving = false;
                });
            }
        }
    };
</script>

<style lang="scss" scoped>

</style>
