<template>
    <v-card>
        <v-card-text>
            <v-row>
                <v-col>
                    <h5 class="subtitle-1 font-weight-regular grey--text">Estado</h5>
                    <span class="headline" :class="statusTextColor">{{ statusText }}</span>
                </v-col>

                <v-col class="flex-grow-0 py-0">
                    <v-switch :input-value="deliveryZone.isActive"
                                :loading="isLoading"
                                @change="onStateSwitchChange"
                    ></v-switch>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
    export default {
        components: {},

        props: {
            deliveryZone: Object
        },

        data() {
            return {
                isLoading: false
            };
        },

        computed: {
            statusText() {
                return this.deliveryZone.isActive ? 'Activa' : 'Inactiva';
            },

            statusTextColor() {
                return this.deliveryZone.isActive ? 'green--text' : 'red--text';
            }
        },

        methods: {
            onStateSwitchChange(val) {
                this.isLoading = true;

                return this.$store.dispatch('deliveryZones/setActive', {
                    id: this.deliveryZone.id,
                    active: val
                }).finally(() => {
                    this.isLoading = false;
                });
            }
        }
    };
</script>

<style lang="scss" scoped>
</style>
