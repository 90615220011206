<template>
    <template-layout>
        <template slot="content">
            <v-container v-if="!isLoading && deliveryZone">
                <v-row align="center" justify="start">
                    <v-btn icon class="black--text" @click="onBackSelected">
                        <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    <h1 class="headline">Zona de Entrega: {{ deliveryZone.name }}</h1>
                </v-row>

                <v-row>
                    <v-col xl="9" lg="8" cols="12" order="1" order-lg="0">
                        <delivery-zone-map :delivery-zone="deliveryZone"></delivery-zone-map>
                    </v-col>

                    <v-col xl="3" lg="4" cols="12">
                        <v-row>
                            <v-col cols="12">
                                <delivery-zone-status :delivery-zone="deliveryZone"></delivery-zone-status>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12">
                                <delivery-zone-info :delivery-zone="deliveryZone"></delivery-zone-info>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12">
                                <delivery-zone-schedules :delivery-zone="deliveryZone"></delivery-zone-schedules>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </template>
    </template-layout>
</template>

<script>
    import TemplateLayout from '@/layout/TemplateLayout.vue';
    import DeliveryZoneInfo from './DeliveryZoneInfo';
    import DeliveryZoneSchedules from './DeliveryZoneSchedules';
    import DeliveryZoneStatus from './DeliveryZoneStatus';
    import DeliveryZoneMap from './DeliveryZoneMap';

    export default {
        components: {
            DeliveryZoneMap,
            DeliveryZoneStatus,
            DeliveryZoneSchedules,
            DeliveryZoneInfo,
            TemplateLayout
        },

        data() {
            return {
                isLoading: false
            };
        },

        computed: {
            deliveryZone() {
                let id = parseInt(this.$route.params.id);

                return this.$store.getters['deliveryZones/getById'](id);
            }
        },

        watch: {},

        created() {
            this.isLoading = true;

            let id = parseInt(this.$route.params.id);

            this.$store.dispatch('deliveryZones/fetchOne', id).then(() => {
                return this.$store.dispatch('deliveryZones/fetchSchedules', id);
            }).finally(() => {
                this.isLoading = false;
            });
        },

        methods: {
            onBackSelected() {
                this.$router.push({ name: 'deliveryZoneList' });
            }
        }
    };
</script>

<style lang="scss" scoped>
</style>
