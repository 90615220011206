<template>
    <v-select
        label="Dia"
        :items="optionsDay"
        v-model="selectedDay"
        :error-messages="errorMessages"
    >
    </v-select>
</template>

<script>
    export default {
        components: {
        },

        props: {
            deliveryZone: Object,
            value: Number,
            errorMessages: Array
        },

        data() {
            return {
                active: false,
                saving: false,

                optionsDay: [
                    { text: 'Lunes', value: 1 },
                    { text: 'Martes', value: 2 },
                    { text: 'Miércoles', value: 3 },
                    { text: 'Jueves', value: 4 },
                    { text: 'Viernes', value: 5 },
                    { text: 'Sabado', value: 6 },
                    { text: 'Domingo', value: 7 }
                ],

                selectedDay: 0
            };
        },

        watch: {
            selectedDay(val) {
                this.$emit('input', val);
            },

            value(val) {
                this.selectedDay = val;
            }
        }
    };
</script>

<style lang="scss" scoped>

</style>
