<template>
    <v-card class="t-card">
        <delivery-zone-add-schedule-drawer
            v-model="showAddScheduleDrawer"
            :deliveryZone="deliveryZone"
            @saved="showAddScheduleDrawer = false"
        ></delivery-zone-add-schedule-drawer>

        <v-card-title>
                <v-col class="pa-0">
                    Horarios de Entrega
                </v-col>

                <v-col class="pa-0 text-end">
                    <v-btn icon @click="showAddScheduleDrawer = !showAddScheduleDrawer">
                        <v-icon>mdi-plus-circle-outline</v-icon>
                    </v-btn>
                </v-col>
        </v-card-title>

        <v-card-text class="px-0">
            <v-simple-table>
                <tbody>
                    <tr v-for="schedule in deliveryZone.schedules" v-bind:key="schedule.id">
                        <td>{{ schedule.day | toDayName | capitalize }}</td>
                        <td>{{ schedule.start }} - {{ schedule.end }}</td>
                        <td align="right">
                            <v-btn text small @click="onDeleteSelected(schedule)">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </td>
                    </tr>
                </tbody>
            </v-simple-table>
        </v-card-text>
    </v-card>
</template>

<script>
    import DeliveryZoneAddScheduleDrawer from './DeliveryZoneAddScheduleDrawer';

    export default {
        components: {
            DeliveryZoneAddScheduleDrawer
        },

        props: {
            deliveryZone: Object
        },

        data() {
            return {
                showAddScheduleDrawer: false
            };
        },

        created() {
        },

        methods: {
            onDeleteSelected(schedule) {
                this.$store.dispatch('deliveryZones/deleteSchedule', {
                    zoneId: this.deliveryZone.id,
                    schedule: schedule
                });
            }
        }
    };
</script>

<style lang="scss" scoped>
</style>
