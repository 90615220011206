<template>
    <v-navigation-drawer
        v-model="active"
        right
        temporary
        fixed
        width="500"
    >
        <v-container class="px-12 pb-12">
            <v-row>
                <v-col>
                    <v-row class="align-center">
                        <v-btn icon class="black--text" @click="active = false">
                            <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>

                        <h1 class="headline" style="white-space: nowrap">Agregar horario de entrega</h1>
                    </v-row>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <validation-observer ref="form">
                        <v-form>
                            <validation-provider name="day" v-slot="{ errors }" rules="required">
                                <week-day-select
                                    v-model="data.day"
                                    :error-messages="errors"
                                />
                            </validation-provider>

                            <validation-provider name="timeStart" v-slot="{ errors }" rules="required">
                                <v-text-field
                                    v-model="data.start"
                                    v-mask="maskTime"
                                    label="Desde"
                                    append-icon="mdi-clock-outline"
                                    :error-messages="errors"
                                />
                            </validation-provider>

                            <validation-provider name="timeEnd" v-slot="{ errors }" rules="required">
                                <v-text-field
                                    v-model="data.end"
                                    v-mask="maskTime"
                                    label="Hasta"
                                    append-icon="mdi-clock-outline"
                                    :error-messages="errors"
                                />
                            </validation-provider>
                        </v-form>
                    </validation-observer>
                </v-col>
            </v-row>
        </v-container>

        <template v-slot:append>
            <v-row class="ma-4">
                <v-spacer></v-spacer>

               <v-btn color="primary"
                    @click="onSaveSelected"
                    :disabled="saving"
                    :loading="saving"
                    dark block
                >
                    Agregar
                </v-btn>
            </v-row>
        </template>
    </v-navigation-drawer>
</template>

<script>
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import WeekDaySelect from '@/ui/WeekDaySelect';

    export default {
        components: {
            ValidationProvider,
            ValidationObserver,
            WeekDaySelect
        },

        props: {
            deliveryZone: Object,
            value: Boolean
        },

        data() {
            return {
                active: false,
                saving: false,

                maskTime: '##:##',

                data: {
                    day: null,
                    start: null,
                    end: null
                }
            };
        },

        watch: {
            active(val) {
                this.$emit('input', val);
            },

            value(val) {
                this.active = val;
            }
        },

        methods: {
            async onSaveSelected() {
                let result = await this.$refs.form.validate();
                if(!result) {
                    return;
                }

                this.saving = true;

                try {
                    await this.$store.dispatch('deliveryZones/addSchedule', { zoneId: this.deliveryZone.id, schedule: this.data });

                    this.$emit('saved');
                    this.data = {
                        day: null,
                        start: null,
                        end: null
                    };
                    this.$refs.form.reset();
                }
                catch (err) {
                    console.error(err);
                }
                finally {
                    this.saving = false;
                }
            }
        }
    };
</script>

<style lang="scss" scoped>

</style>
