<template>
    <v-card>
        <v-card-title>
            <v-col class="pa-0">
                Mapa
            </v-col>

            <v-col class="pa-0 text-end">
                <v-btn v-if="!isEditing && !isSaving" icon @click="isEditing = true">
                    <v-icon>mdi-pencil-outline</v-icon>
                </v-btn>

                <v-btn v-if="isEditing || isSaving" small color="primary" @click="onSaveSelected" :loading="isSaving">
                    <v-icon>mdi-content-save-outline</v-icon>Guardar
                </v-btn>
            </v-col>
        </v-card-title>

        <v-card-text class="pa-0">
            <tui-google-map
                style="height: 500px;"
                :polygons="deliveryZonePolygons"
                :center="deliveryZoneCenter"
                :editable="isEditing"
                v-model="editedPolygons"
            ></tui-google-map>
        </v-card-text>
    </v-card>
</template>

<script>
    /* global google */

    import TuiGoogleMap from '@/ui/TUIGoogleMap';

    export default {
        components: {
            TuiGoogleMap
        },

        props: {
            deliveryZone: Object
        },

        data() {
            return {
                isSaving: false,
                isEditing: false,

                editedPolygons: []
            };
        },

        computed: {
            deliveryZonePolygons() {
                // Define the LatLng coordinates for the polygon's path.
                let polygons = [];
                for(let poly of this.deliveryZone.polygons) {
                    let latLngArr = [];

                    for(let point of poly) {
                        let c = point.split(',');
                        latLngArr.push({
                            lat: parseFloat(c[0]),
                            lng: parseFloat(c[1])
                        });
                    }

                    polygons.push(latLngArr);
                }

                return polygons;
            },

            deliveryZoneCenter() {
                let bounds = new google.maps.LatLngBounds();

                for(let poly of this.deliveryZone.polygons) {
                    for(let point of poly) {
                        let c = point.split(',');
                        bounds.extend({
                            lat: parseFloat(c[0]),
                            lng: parseFloat(c[1])
                        });
                    }
                }

                let center = bounds.getCenter();
                return {
                    lat: center.lat(),
                    lng: center.lng()
                };
            }
        },

        methods: {
            onSaveSelected(val) {
                this.isEditing = false;

                let polygonsString = '';

                for(let i = 0; i < this.editedPolygons.length; ++i) {
                    let polygon = this.editedPolygons[i];
                    let path = polygon.getPath();
                    let coordinates = '';

                    if(i !== 0) {
                        polygonsString += '|';
                    }

                    for(let k = 0; k < path.getLength(); ++k) {
                        let coord = path.getAt(k).toString();

                        if(k < path.getLength() - 1) {
                            coord += ';';
                        }

                        coord = coord.replace('(', '');
                        coord = coord.replace(')', '');

                        coordinates += coord;
                    }

                    polygonsString += coordinates;
                }

                // Submit it
                this.isSaving = true;
                this.$store.dispatch('deliveryZones/patch', { id: this.deliveryZone.id, data: { coordinates: polygonsString } }).then(() => {

                }).finally(() => {
                    this.isSaving = false;
                });
            }
        }
    };
</script>

<style lang="scss" scoped>
</style>
