<template>
    <v-card>
        <v-navigation-drawer
            v-model="showEditDrawer"
            right
            temporary
            fixed
            width="500"
        >
            <delivery-zone-edit-info-form
                :delivery-zone="deliveryZone"
                @saved="showEditDrawer = false"
            ></delivery-zone-edit-info-form>
        </v-navigation-drawer>

        <v-card-title>
            <v-col class="pa-0">
                Zona
            </v-col>

            <v-col class="pa-0 text-end">
                <v-btn icon @click="showEditDrawer = true">
                    <v-icon>mdi-pencil-outline</v-icon>
                </v-btn>
            </v-col>
        </v-card-title>

        <v-card-text class="px-0">
            <v-simple-table>
                <tbody>
                    <tr>
                        <td>Nombre</td>
                        <td align="right">{{ deliveryZone.name }}</td>
                    </tr>
                    <tr>
                        <td>Costo</td>
                        <td align="right">{{ deliveryZone.shippingCost | toCurrency }}</td>
                    </tr>
                    <tr>
                        <td>Tiempo de Espera</td>
                        <td align="right">{{ deliveryZone.waitingTime }}</td>
                    </tr>
                </tbody>
            </v-simple-table>
        </v-card-text>
    </v-card>
</template>

<script>
    import DeliveryZoneEditInfoForm from './DeliveryZoneEditInfoForm';

    export default {
        components: { DeliveryZoneEditInfoForm },

        props: {
            deliveryZone: Object
        },

        data() {
            return {
                showEditDrawer: false
            };
        },

        methods: {}
    };
</script>

<style lang="scss" scoped>
</style>
